import React from "react";
import "./Footer.css";
import { assets } from "../../Assets/assets";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaAngleDoubleRight } from "react-icons/fa";

function Footer() {
  return (
    <div>
      <footer>
        <div className="footer-top">
          <div className="footer-logo">
            <div className="logo">
              <img src={assets.Logo} alt="" />
            </div>
            <ul className="social">
              <li>
                <a
                  href="https://www.facebook.com/nuerobots?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/nuerobots?igsh=MWoxOHN6YmllaHBmdg=="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/nuerobots/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li>
                <a
                  href="https://youtube.com/@NUEROBOTS_?si=IChm-CQOOuPr9DZU"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube />
                </a>
              </li>
            </ul>
          </div>
          <ul className="solution">
            <h3>IT Solution</h3>
            <li>
              <FaAngleDoubleRight />
              App Development
            </li>
            <li>
              <FaAngleDoubleRight />
              Web Development
            </li>
            <li>
              <FaAngleDoubleRight />
              Digital Marketing
            </li>
            <li>
              <FaAngleDoubleRight />
              Cloud Computing
            </li>
            <li>
              <FaAngleDoubleRight />
              IT Consulting
            </li>
          </ul>
          <ul className="quik">
            <h3>Quick Link</h3>
            <li>
              <FaAngleDoubleRight />
              About Nuerobots
            </li>
            <li>
              <FaAngleDoubleRight />
              Our Services
            </li>
            <li>
              <FaAngleDoubleRight />
              Our Projects
            </li>
          </ul>
          <div className="contact">
            <h3>Contact Us</h3>
            <p>
              Nuerobots Business Park
              <p>3rd Floor, Aysha Complex,</p>
              <p>Calicut Rd, Perinthalmanna</p> Kerala-679 322
            </p>
            <h4>Opening Hours:</h4>
            <p>Mon - Sat: 09.00 AM - 6.00 PM</p>
            <h4>Phone Call:</h4>
            <div className="phone">
              <a href="tel:+91701-2982-168">+91 701-2982-168</a>
              <a href="tel:+91773-6504-440">+91 773-6504-440</a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© All Copyright 2024 by Nuerobots</p>
          <ul className="term">
            <li>Terms & Condition</li>
            <li>Privacy Policy</li>
          </ul>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
