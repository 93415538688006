import React from "react";
import Spotlight from "../Spotlight/Spotlight";
import About from "../About/About";
import Career from "../Career/Career";
import Achievements from "../Achievements/Achievements";
import Footer from "../Footer/Footer";
import Testimonial from "../Ṭestimonial/Testimonial";
import Header from "../Header/Header";

function Home() {
  return (
    <div>
      <Header />
      <Spotlight />
      <About />
      <Career />
      <Achievements />
      {/* <Testimonial /> */}
      <Footer />
    </div>
  );
}

export default Home;
