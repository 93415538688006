import React from "react";
import "./Spotlight.css";
import { FaWhatsapp } from "react-icons/fa";

function Spotlight() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const phoneNumber = "+917736504440";
  const whatsappUrl = isMobile
    ? `https://api.whatsapp.com/send?phone=${encodeURIComponent(phoneNumber)}`
    : `https://web.whatsapp.com/send?phone=${encodeURIComponent(phoneNumber)}`;

  return (
    <div className="spotlight" id="spotlight">
      <div className="spotlight-content">
        <div className="content">
          <h6>TECHNOLOGY RELETED CONSULTANCY</h6>
          <h1>
            We Convert
            <span> Concepts </span> Into Technology
          </h1>
          <p>
            Converting concepts into technology involves taking abstract ideas
            and turning them into practical, functional solutions that can be
            implemented using various tools, methods, and techniques.
          </p>
          <button className="explore">Explore More </button>
        </div>
      </div>
      <div className="whatsapp">
        <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
          <FaWhatsapp />
        </a>
      </div>
    </div>
  );
}

export default Spotlight;
