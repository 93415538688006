import Logo from "./Images/Neurobots Logo.webp";
import AboutBimg from "./Images/imgb.webp";
import AboutSimg from "./Images/imgs.webp";
import Case from "./Images/Heading 5 → SVG.webp";
import App from "./Images/appdevelopment.webp";
import Web from "./Images/webdevelopmnt.png";
import Digital from "./Images/digital marketing.webp";
import Consultancy from "./Images/itconsulteccy.webp";
import Intership from "./Images/intenship.webp";
import MobileIcon from "./Images/mobike.icon.webp";
import WebIcon from "./Images/web.icon.png";
import DmIcon from "./Images/dm.icon.webp";
import ItIcon from "./Images/it.icon.webp";
import InterIcon from "./Images/intern.icon.webp";
import Shape from "./Images/service-two-item-shape.png.svg";
import Gala from "./Images/teas.jpg";
import Mehr from "./Images/kpcc.jpg";
import green from "./Images/millath.jpg";
import growth from "./Images/forwayand.jpg";
import save from "./Images/saveabdul.jpg";
import Svg from "./Images/SVG.svg";
import StarFour from "./Images/4star.png";
import StarFive from "./Images/5star.png";
import It_Cons from "./Images/it-consul.jpg";
import Beehivie from "./Images/behive.png";
import Basics from "./Images/basics.png";
import Expert from "./Images/expert.png";
import Real_proj from "./Images/real-project.png";
import Workshop from "./Images/nuerobots ad3 1.png";
import Flutter from "./Images/flutter.png";
import XCode from "./Images/xcode.png";
import Studio from "./Images/android_studio.png";
import Ios from "./Images/ios.png";
import Java from "./Images/java.png";
import Android from "./Images/android.png";
import Firebase from "./Images/firebase.png";
import Figma from "./Images/figma.png";
import xd from "./Images/xd.png";
import ps from "./Images/ps.png";
import ai from "./Images/ai.png";

export const assets = {
  Logo,
  XCode,
  Java,
  Figma,
  Studio,
  xd,
  Firebase,
  Ios,
  ps,
  ai,
  Android,
  AboutBimg,
  AboutSimg,
  Beehivie,
  Case,
  App,
  Web,
  Digital,
  Consultancy,
  It_Cons,
  Intership,
  MobileIcon,
  WebIcon,
  DmIcon,
  ItIcon,
  InterIcon,
  Shape,
  Gala,
  Mehr,
  green,
  growth,
  save,
  Svg,
  StarFour,
  StarFive,
  Basics,
  Expert,
  Real_proj,
  Workshop,
  Flutter,
};
