import React from "react";
import "./About.css";
import { assets } from "../../Assets/assets";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

function About() {
  return (
    <div className="about" id="about">
      <div
        className="about-img"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1500"
        data-aos-easing="ease-in-out"
      >
        <div className="img-b">
          <img src={assets.AboutBimg} alt="AboutBimg" />
        </div>
        <div className="img-s">
          <img src={assets.AboutSimg} alt="AboutSimg" />
        </div>
      </div>
      <div
        className="about-content"
        data-aos="fade-down"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1500"
        data-aos-easing="ease-in-out"
      >
        <h3>
          Empowering Your Success with <span>Dependable</span> IT Solutions
        </h3>
        <p>
          Building a strong foundation with robust infrastructure and security
          measures to protect data and systems, while providing user training
          and support ensures efficient use of technology.
        </p>
        <button>Contact Us</button>
      </div>
    </div>
  );
}

export default About;
