import React, { useEffect, useState } from "react";
import "./Header.css";
import { assets } from "../../Assets/assets";
import MobileMenu from "../MobileMenu/MobileMenu";

import { Link } from "react-router-dom";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const updateActiveSection = () => {
    const spotlightSection = document.getElementById("spotlight");
    const serviceSection = document.getElementById("about");
    const workSection = document.getElementById("achievement");
    const aboutSection = document.getElementById("career");

    const scrollPosition = window.scrollY + window.innerHeight / 2;

    if (spotlightSection && serviceSection && workSection && aboutSection) {
      if (
        scrollPosition < serviceSection.offsetTop &&
        scrollPosition > spotlightSection.offsetTop
      ) {
        setActiveSection("spotlight");
      } else if (
        scrollPosition < workSection.offsetTop &&
        scrollPosition > serviceSection.offsetTop
      ) {
        setActiveSection("about");
      } else if (
        scrollPosition < aboutSection.offsetTop &&
        scrollPosition > workSection.offsetTop
      ) {
        setActiveSection("achievement");
      } else {
        setActiveSection("career");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", updateActiveSection);

    return () => {
      window.removeEventListener("scroll", updateActiveSection);
    };
  }, []);

  return (
    <div>
      <header className={`${isMenuOpen ? "open" : ""}`}>
        <div className="logo">
          <img src={assets.Logo} alt="Logo" />
        </div>
        <ul className="nav">
          <li
            className={activeSection === "spotlight" ? "active" : ""}
            onClick={() => scrollToSection("spotlight")}
          >
            <Link to="/" className="link">
              Home
            </Link>
          </li>
          <li
            className={activeSection === "about" ? "active" : ""}
            onClick={() => scrollToSection("about")}
          >
            About
          </li>
          <li
            className={activeSection === "career" ? "active" : ""}
            onClick={() => scrollToSection("career")}
          >
            Services
          </li>
          <li
            className={activeSection === "achievement" ? "active" : ""}
            onClick={() => scrollToSection("achievement")}
          >
            Projects
          </li>
          <li>
            <Link to="/internship" className="link">
              Internship
            </Link>
          </li>
        </ul>
        <button>
          <a href="https://api.whatsapp.com/send?phone=7736504440">
            Contact Us
          </a>
        </button>
        <div className="menu" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>
      <MobileMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
    </div>
  );
}

export default Header;
