import React from "react";
import "./Internship.css";
import Header from "../../components/Header/Header";
import InternSpotlight from "../../components/InternSpotlight/InternSpotlight";
import AppDetails from "../../components/AppDetails/AppDetails";
import Footer from "../../components/Footer/Footer";
import Tools from "../../components/Tools/Tools";

function Internship() {
  return (
    <div className="internship">
      <Header />
      <InternSpotlight />
      <AppDetails />
      <Tools />
      <Footer />
    </div>
  );
}

export default Internship;
