import React from "react";
import "./AppDetails.css";
import { assets } from "../../Assets/assets";

function AppDetails() {
  return (
    <div className="appdetails">
      <div className="top-case">
        <img src={assets.Case} alt="Case" />
        <h4>
          Why Enroll For Flutter Application Development Internship at
          nuerobots?
        </h4>
      </div>
      <div className="career-items">
        <div className="items">
          <div className="img-box">
            <img src={assets.Basics} alt="" className="main" />
          </div>
          <div className="img-addition">
            <img src={assets.ItIcon} alt="" className="icon" />
            <img src={assets.Shape} alt="" className="shape" />
          </div>
          <div className="content-box">
            <h6>Learn from Basics</h6>
            <p>
              The choice of development approach, platforms, technologies, and
              tools depends on factors such as project requirements, budget,
              timeline, and target audience.
            </p>
          </div>
        </div>
        <div className="items">
          <div className="img-box">
            <img src={assets.Expert} alt="" className="main" />
          </div>
          <div className="img-addition">
            <img src={assets.ItIcon} alt="" className="icon" />
            <img src={assets.Shape} alt="" className="shape" />
          </div>
          <div className="content-box">
            <h6>Learn from Industry Experts</h6>
            <p>
              Collaborating with developers enhances skills, real world
              experience and career prospects.
            </p>
          </div>
        </div>
        <div className="items">
          <div className="img-box">
            <img src={assets.Real_proj} alt="" className="main" />
          </div>
          <div className="img-addition">
            <img src={assets.ItIcon} alt="" className="icon" />
            <img src={assets.Shape} alt="" className="shape" />
          </div>
          <div className="content-box">
            <h6>Learn from Real Industry Projects</h6>
            <p>
              Engaging in real-time projects enables hands-on learning and
              significant professional growth.
            </p>
          </div>
        </div>
        {/* <div className="items">
          <div className="img-box">
            <img src={assets.Workshop} alt="" className="main" />
          </div>
          <div className="img-addition">
            <img src={assets.ItIcon} alt="" className="icon" />
            <img src={assets.Shape} alt="" className="shape" />
          </div>
          <div className="content-box">
            <h6>App Development Workshop</h6>
            <p>
              Engaging in real-time projects enables hands-on learning and
              significant professional growth.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default AppDetails;
