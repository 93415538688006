import React from "react";
import "./Tools.css";
import { assets } from "../../Assets/assets";

function Tools() {
  return (
    <div className="tools">
      <h4>Tools Covered</h4>
      <ul className="used">
        <li>
          <img src={assets.Flutter} alt="" />
        </li>
        <li>
          <img src={assets.XCode} alt="" />
        </li>
        <li>
          <img src={assets.Studio} alt="" />
        </li>
        <li>
          <img src={assets.Ios} alt="" />
        </li>
        <li>
          <img src={assets.Java} alt="" />
        </li>
        <li>
          <img src={assets.Android} alt="" />
        </li>
        <li>
          <img src={assets.Firebase} alt="" />
        </li>
        <li>
          <img src={assets.Figma} alt="" />
        </li>
        <li>
          <img src={assets.xd} alt="" />
        </li>
        <li>
          <img src={assets.ai} alt="" />
        </li>
        <li>
          <img src={assets.ps} alt="" />
        </li>
      </ul>
    </div>
  );
}

export default Tools;
