import React from "react";
import "./MobileMenu.css";
import { Link } from "react-router-dom";

function MobileMenu({ isMenuOpen, toggleMenu }) {
  return (
    <div className="mobile">
      <ul className={`nav ${isMenuOpen ? "open" : ""}`}>
        <li>
          <Link to="/" className="link">
            Home
          </Link>
        </li>
        <li>About</li>
        <li>Services</li>
        <li>Career</li>
        <li>
          <Link to="/internship" className="link">
            Internship
          </Link>
        </li>
      </ul>
    </div>
  );
}
export default MobileMenu;
