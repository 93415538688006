import React from "react";
import "./Achievements.css";
import { assets } from "../../Assets/assets";
import { FaArrowRight } from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Achievements() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 610,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className="achievement" id="achievement">
      <div className="top-achive">
        <div className="top-left">
          <div className="top-case">
            <img src={assets.Case} alt="Case" />
            <h6> FROM OUR PROJECTS</h6>
          </div>
          <h3>Our Achievements</h3>
        </div>
        <div className="top-right">
          <div className="round"></div>
          <div className="bg-round"></div>
        </div>
      </div>
      <div className="card-list">
        <Slider {...settings} className="slickk">
          <div className="card">
            <div className="top-img">
              <img src={assets.save} alt="" />
            </div>
            <div className="bottom-content">
              <h6>Save Abdul Rahim</h6>
              <FaArrowRight />
            </div>
          </div>

          <div className="card">
            <div className="top-img">
              <img src={assets.Mehr} alt="" />
            </div>
            <div className="bottom-content">
              <h6>KPCC 138</h6>
              <FaArrowRight />
            </div>
          </div>

          <div className="card">
            <div className="top-img">
              <img src={assets.green} alt="" />
            </div>
            <div className="bottom-content">
              <h6>Quaid-E-Millath</h6>
              <FaArrowRight />
            </div>
          </div>

          <div className="card">
            <div className="top-img">
              <img src={assets.growth} alt="" />
            </div>
            <div className="bottom-content">
              <h6>For Wayanad</h6>
              <FaArrowRight />
            </div>
          </div>

          <div className="card">
            <div className="top-img">
              <img src={assets.Gala} alt="" />
            </div>
            <div className="bottom-content">
              <h6>Tea Gala</h6>
              <FaArrowRight />
            </div>
          </div>
          <div className="card">
            <div className="top-img">
              <img src={assets.Mehr} alt="" />
            </div>
            <div className="bottom-content">
              <h6>KPCC 138</h6>
              <FaArrowRight />
            </div>
          </div>

          <div className="card">
            <div className="top-img">
              <img src={assets.green} alt="" />
            </div>
            <div className="bottom-content">
              <h6>Quaid-E-Millath</h6>
              <FaArrowRight />
            </div>
          </div>

          <div className="card">
            <div className="top-img">
              <img src={assets.growth} alt="" />
            </div>
            <div className="bottom-content">
              <h6>For Wayanad</h6>
              <FaArrowRight />
            </div>
          </div>

          <div className="card">
            <div className="top-img">
              <img src={assets.Gala} alt="" />
            </div>
            <div className="bottom-content">
              <h6>Tea Gala</h6>
              <FaArrowRight />
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default Achievements;
