import React from "react";
import "./Career.css";
import { assets } from "../../Assets/assets";

function Career() {
  return (
    <div className="career" id="career">
      <div className="top-case">
        <img src={assets.Case} alt="Case" />
        <h6> FROM OUR CASE STUDIES</h6>
      </div>
      <h4>Our Premium IT Solutions</h4>
      <div className="career-items">
        <div className="items">
          <div className="img-box">
            <img src={assets.App} alt="" className="main" />
          </div>
          <div className="img-addition">
            <img src={assets.ItIcon} alt="" className="icon" />
            <img src={assets.Shape} alt="" className="shape" />
          </div>
          <div className="content-box">
            <h6>App Development</h6>
            <p>
              The choice of development approach, platforms, technologies, and
              tools depends on factors such as project requirements, budget,
              timeline, and target audience.
            </p>
            {/* <button>Read More </button> */}
          </div>
        </div>
        <div className="items">
          <div className="img-box">
            <img src={assets.Web} alt="" className="main" />
          </div>
          <div className="img-addition">
            <img src={assets.ItIcon} alt="" className="icon" />
            <img src={assets.Shape} alt="" className="shape" />
          </div>
          <div className="content-box">
            <h6>Web Development</h6>
            <p>
              This involves implementing secure coding practices, using
              encryption and authentication mechanisms, and staying updated on
              security best practices.
            </p>
            {/* <button>Read More </button> */}
          </div>
        </div>
        <div className="items">
          <div className="img-box">
            <img src={assets.Digital} alt="" className="main" />
          </div>
          <div className="img-addition">
            <img src={assets.ItIcon} alt="" className="icon" />
            <img src={assets.Shape} alt="" className="shape" />
          </div>
          <div className="content-box">
            <h6>Digital Marketing</h6>
            <p>
              With advancements in technology and changes in consumer behavior,
              providing new opportunities for businesses to connect with their
              target audience and drive growth.
            </p>
            {/* <button>Read More </button> */}
          </div>
        </div>
        <div className="items">
          <div className="img-box">
            <img src={assets.It_Cons} alt="" className="main" />
          </div>
          <div className="img-addition">
            <img src={assets.ItIcon} alt="" className="icon" />
            <img src={assets.Shape} alt="" className="shape" />
          </div>
          <div className="content-box">
            <h6>IT Consultancy</h6>
            <p>
              Providing ongoing support and maintenance services to ensure that
              IT systems.
            </p>
            {/* <button>Read More </button> */}
          </div>
        </div>
        <div className="items">
          <div className="img-box">
            <img src={assets.Consultancy} alt="" className="main" />
          </div>
          <div className="img-addition">
            <img src={assets.ItIcon} alt="" className="icon" />
            <img src={assets.Shape} alt="" className="shape" />
          </div>
          <div className="content-box">
            <h6>Internships</h6>
            <p>
              Offering internships in these areas helps individuals develop
              practical skills in high demand in the tech industry.
            </p>
            {/* <button>Read More </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Career;
