import { useEffect, useState } from "react";
import "./App.css";
import Home from "./components/Home/Home";
import PropagateLoader from "react-spinners/PropagateLoader";
import Internship from "./Pages/Internship/Internship";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  return (
    <div className="App">
      {loading ? (
        <div className="loader-container">
          <PropagateLoader color="#36d7b7" size={20} />
        </div>
      ) : (
        <Router>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/internship" element={<Internship />} />
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
