import React from "react";
import "./InternSpotlight.css";

function InternSpotlight() {
  return (
    <div className="spotlight-intern">
      <div className="content">
        <h4>Flutter App Development Internship Program</h4>
        <p>
          The Neurobots Flutter Internship Program offers an excellent
          opportunity to enhance your mobile App development skills and acquire
          valuable experience. This program provides comprehensive coverage of
          the fundamentals of Flutter, equipping you with the knowledge
          necessary to excel in the dynamic world of technology.
        </p>
      </div>
    </div>
  );
}

export default InternSpotlight;
